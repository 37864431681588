export const TIER_LEVELS = {
  FREE: 1,
  TRIAL: 10,
  PAID: 20,
  ENTERPRISE: 30,
};

export const DEFAULT_TRIAL_DAYS = 14;
export const ORG_PERMISSIONS = {
  OWNER: 'owner',
  USER: 'user',
};

export const TIER_LEVEL_TO_TITLE = {
  [TIER_LEVELS.FREE]: 'Developer',
  [TIER_LEVELS.TRIAL]: 'Team',
  [TIER_LEVELS.PAID]: 'Team',
  [TIER_LEVELS.ENTERPRISE]: 'Enterprise',
};

export const PLAN_NAMES = Object.freeze({
  DEVELOPER: 'Developer',
  TEAMS: 'Teams',
  ENTERPRISE: 'Enterprise',
});

export const jiraServerQodoConnect =
  'https://qodo-merge-docs.qodo.ai/core-abilities/fetching_ticket_context/#jira-serverdata-center';
export const jiraCloudQodoConnect =
  'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=8krKmA4gMD8mM8z24aRCgPCSepZNP1xf&scope=read%3Ajira-work%20offline_access&redirect_uri=https%3A%2F%2Fregister.jira.pr-agent.codium.ai&state=qodomerge&response_type=code&prompt=consent';
