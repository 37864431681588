// Context
export const PORTAL = 'portal';
export const WEBAPP = 'webapp';

// User Interactions
export const CREATE_TEAM_CLICK = 'create team click';
export const CLOSE_TEAM_CLICK = 'close team click';
export const LOGIN_WITH_GITHUB_CLICK = 'login with github click';
export const LOGIN_WITH_GOOGLE_CLICK = 'login with google click';
export const LOGIN_WITH_SSO = 'login with sso';
export const INVITE_MEMBER_TO_TEAM_CLICK = 'invite member to team click';
export const INVITE_MEMBER_TO_ORGANIZATIONS_CLICK =
  'invite member to organizations click';
export const REMOVE_MEMBER_FROM_TEAM_CLICK = 'remove member from team click';
export const REMOVE_MEMBER_FROM_ORGANIZATIONS_CLICK =
  'remove member from team click';
export const REMOVE_USER_INVITE_FROM_TEAM_CLICK =
  'remove user invite from team click';
export const JOIN_DISCORD_CLICK = 'join discord click';
export const MANAGE_STRIPE_TEAM_CLICK = 'manage stripe team click';
export const MANAGE_STRIPE_ORG_CLICK = 'manage stripe org click';
export const VSCODE_MARKETPLACE_LINK_CLICK = 'vscode marketplace link click';
export const JB_MARKETPLACE_LINK_CLICK = 'jb marketplace link click';
export const JIRA_CLOUD_CONNECT_CLICK = 'jira cloud connect click';
export const JIRA_SERVER_CONNECT_CLICK = 'jira server connect click';
export const DISCONNECT_GIT_PROVIDER_CLICK = 'disconnect git provider click';
export const CONNECT_GITHUB_TO_PR_AGENT_PRO_CLICK = 'connect github click';
export const CONNECT_GITLAB_TO_PR_AGENT_PRO_CLICK = 'connect gitlab click';
export const CONNECT_BITBUCKET_TO_PR_AGENT_PRO_CLICK =
  'connect bitbucket click';
export const MANAGE_SSO = 'manage sso';
export const COPY_SSO = 'copy sso';
export const ORG_MANAGE_ENV_CLICK = 'org manage env click';

// API
export const GET_TEAM_ERROR = 'get team error';
export const GET_USER_ERROR = 'get user error';
export const GET_PLANS_ERROR = 'get plans error';
export const GET_TEAM_USERS_ERROR = 'get team users error';
export const UPDATE_TEAM_ERROR = 'update team error';
export const DISCONNECT_GIT_PROVIDER_ERROR = 'disconnect git provider error';
export const CREATE_TEAM_ERROR = 'create team error';
export const CREATE_STRIPE_SESSION_ERROR = 'create stripe session error';
export const GET_INVITES_ERROR = 'get invites error';
export const GET_ORGANIZATIONS_ERROR = 'get organizations error';
export const GET_ORG_MEMBERS_ERROR = 'get org members error';
export const GET_ORG_TEAMS_ERROR = 'get org teams error';
export const SSO_SETUP_ERROR = 'sso setup error';
export const GET_ORG_ENV_MEMBERS_ERROR = 'get org env members error';
export const UPDATE_ORG_ERROR = 'update organization error';
export const UPDATE_USER_ERROR = 'update user error';
export const ERROR_INVITE_USER_TO_ORG = 'invite user to org error';
