const Style = {
  base: `px-3 py-1 rounded-2xl border border-neutral-700 justify-start items-center gap-1 inline-flex  text-xs font-semibold font-['Inter'] tracking-wide bg-opacity-30 uppercase`,
  red: 'bg-red-900 text-red-400 border-red-700',
  blue: 'bg-blue-500',
  green: `text-teal-400 border-teal-700 bg-teal-900`,
  orange: 'bg-orange-500',
  purple: 'text-indigo-300 bg-indigo-900 border-indigo-700',
};

export default Style;
