import { useMutation, useQuery } from '@tanstack/react-query';
import { ENDPOINTS, apiService } from 'api';
import { useUser } from 'components/hooks/useUser';
import { Button, Checkbox, Icon } from 'components/lib';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Dialog } from 'components/lib';

export const RequestAccess = () => {
  const { user, isOrganizationOwner } = useUser();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const { data: requests, refetch: refetchRequests } = useQuery({
    queryKey: ['organization.requests'],
    queryFn: async () => {
      return await apiService.callApi({
        endpoint: ENDPOINTS.REQUEST_ACCESS,
        method: 'get',
      });
    },
    enabled: isOrganizationOwner,
    select: ({ requests }) => requests,
  });

  const { mutateAsync: responedRequestMutation, isPending } = useMutation({
    mutationFn: async ({ request_ids, approved }) => {
      if (isEmpty(request_ids)) {
        return;
      }
      return apiService.callApi({
        endpoint: ENDPOINTS.REQUEST_ACCESS,
        method: 'patch',
        data: { request_ids, approved },
      });
    },
    onSuccess: () => {
      setSelectedIds(selectedIds.filter((id) => !selectedIds.includes(id)));
      refetchRequests();
      if (isEmpty(requests)) {
        setDialogOpen(false);
      }
    },
  });

  if (isEmpty(requests)) return null;

  const isAllSelected = selectedIds.length === requests.length;

  return (
    <div className="flex flex-col items-start space-y-4 md:space-y-6 p-3 md:p-4 rounded-md self-stretch bg-brand-300">
      <div className="flex justify-between w-full">
        <span className="flex flex-row gap-2 font-medium text-sm">
          <Icon image="bell" color="#fff" size={24} />

          <div className="px-2 bg-white rounded justify-start items-center inline-flex text-indigo-800 text-xs font-bold">
            {requests.length}
          </div>

          <span>
            Users requested access to your organization. Review requests and
            grant access to recognized users.
          </span>
        </span>
      </div>
      <div className="flex flex-row gap-4">
        <Button
          loading={isPending}
          text="Review access requests"
          small
          className="text-white border-solid border border-slate-200 hover:bg-brand-200 bg-brand-400"
          action={() => {
            const allIds = requests.map(({ id }) => id);
            setSelectedIds(allIds);
            setDialogOpen(true);
          }}
        />
      </div>
      {isDialogOpen && (
        <Dialog
          title="Review access requests"
          onClose={() => setDialogOpen(false)}
        >
          <div className="min-w-[500px] flex flex-col h-[500px]">
            <div className="flex justify-end mb-2 text-brand-200 cursor-pointer">
              {!isAllSelected && (
                <div
                  onClick={() => {
                    const allIds = requests.map(({ id }) => id);
                    setSelectedIds(allIds);
                  }}
                >
                  select all
                </div>
              )}
              {isAllSelected && (
                <div onClick={() => setSelectedIds([])}>unselect all</div>
              )}
            </div>
            <div className="flex-grow overflow-y-auto mb-4">
              <div className="flex flex-col gap-2">
                {requests.map((request) => (
                  <div
                    className="full-width flex justify-between"
                    key={request.id}
                  >
                    <div>{request.email}</div>
                    <div className="w-fit">
                      <Checkbox
                        callback={() => {
                          if (selectedIds.includes(request.id)) {
                            setSelectedIds(
                              selectedIds.filter((id) => id !== request.id),
                            );
                          } else {
                            setSelectedIds([...selectedIds, request.id]);
                          }
                        }}
                        checked={selectedIds.includes(request.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between mt-auto">
              <Button
                small
                text="Decline selected"
                outline
                action={() =>
                  responedRequestMutation({
                    request_ids: selectedIds,
                    approved: false,
                  })
                }
                className="w-fit"
              />
              <div className="flex gap-2">
                <Button
                  small
                  outline
                  text="Cancel"
                  action={() => setDialogOpen(false)}
                  className="w-fit"
                />
                <Button
                  text="Approve selected"
                  small
                  action={() =>
                    responedRequestMutation({
                      request_ids: selectedIds,
                      approved: true,
                    })
                  }
                  className="w-fit"
                />
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
