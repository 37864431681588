import { SubNav } from 'components/lib';
import { TeamTabLabel } from './teamTabLabel';
import { useUser } from 'components/hooks/useUser';
import { useOrganization } from 'components/hooks/useOrganization';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationTeams } from '../../../views/account/switchTeamDropdown';
import { useEffect, useState } from 'react';

export const PortalNav = () => {
  const { user, isOrganizationOwner } = useUser();
  const [teamId, setTeamId] = useState(undefined);
  const { organization } = useOrganization();

  const { data: orgTeams } = useQuery({
    queryKey: ['orgTeams'],
    queryFn: getOrganizationTeams,
    enabled: isOrganizationOwner,
  });

  useEffect(() => {
    const userTeamId = user?.teams?.[0]?.team_id;
    if (userTeamId) {
      setTeamId(userTeamId);
    } else if (orgTeams) {
      const teamId = orgTeams?.teams?.[0]?.id;
      if (teamId) {
        setTeamId(teamId);
      }
    }
  }, [user?.teams, orgTeams]);

  let routes = [
    { link: '/', label: 'Home' },
    {
      link: teamId ? `/team/${teamId}/users` : '/team',
      label: <TeamTabLabel tierLevel={user?.tier_level} />,
    },
  ];

  if (isOrganizationOwner) {
    routes.push({ link: '/organization', label: 'Organization' });
    if (organization?.usage_dashboard_url) {
      routes.push({ link: '/dashboard', label: 'Dashboard' });
    }
  }

  routes.push({ link: '/qodo-merge', label: 'Qodo Merge' });

  return <SubNav items={routes} />;
};
