import { useUser } from 'components/hooks/useUser';
import { Animate, Icon, Link } from 'components/lib';
import { PortalNav } from 'components/nav/sub/portal';
import { ConnectSection } from './connectSection';
import { UpgradeSection } from './upgradeSection';
import GitScreenshot from './GitScreenshot.svg';
import { QodoMergeStep } from './qodoMergeStep';
import { Code } from 'components/qodo/qodoCode';
import { jiraServerQodoConnect, jiraCloudQodoConnect } from '../../constants';

const getTicketManagementLinks = (isOrganizationOwner) => [
  {
    text: 'Jira Cloud',
    url: jiraCloudQodoConnect,
    openInNewTab: true,
    disabled: !isOrganizationOwner,
  },
  {
    text: 'Jira Server',
    url: jiraServerQodoConnect,
    openInNewTab: true,
    disabled: !isOrganizationOwner,
  },
  {
    text: 'Github Issues (automatically integrated for GitHub users)',
  },
];

const gitProvidersLinks = [
  {
    text: 'GitHub settings',
    url: 'https://github.com/apps/qodo-merge-pro',
  },
  {
    text: 'Bitbucket settings',
    url: 'https://bitbucket.org/site/addons/authorize?addon_key=d6df813252c37258',
  },
  {
    text: 'GitLab settings',
    url: 'https://qodo-merge-docs.qodo.ai/installation/pr_agent_pro/#install-pr-agent-pro-for-gitlab-teams-enterprise',
  },
];

const getGitProvidersStepData = (isOrganizationOwner) => [
  {
    title: 'Step 2 (For admins only) - Enable each repository in settings',
    subtitle:
      'Enable the Qodo Merge Pro and configure it inside the repository settings.',
    children: (
      <div className="flex gap-6">
        {gitProvidersLinks.map(({ url, disabled, text, openInNewTab }) => {
          if (!url)
            return (
              <div key={text} className="font-medium text-neutral-400">
                {text}
              </div>
            );

          return (
            <div className="flex gap-1 items-center" key={text}>
              <Link
                disabled={!!disabled}
                url={url}
                text={text}
                openInNewTab={openInNewTab}
                className="text-indigo-300 hover:underline font-medium"
              />
              <Icon image="external-link" color="purple" />
            </div>
          );
        })}
      </div>
    ),
  },
  {
    title:
      'Step 3 (Recommended, for admins only) - Connect your ticket manager',
    subtitle:
      'Link your ticket management system to automatically bridge your code reviews with tickets and enrich them with relevant context and requirements.',
    children: (
      <div className="flex gap-6">
        {getTicketManagementLinks(isOrganizationOwner).map(
          ({ url, disabled, text, openInNewTab }) => {
            if (!url)
              return (
                <div key={text} className="font-medium text-neutral-400">
                  {text}
                </div>
              );

            return (
              <div className="flex gap-1 items-center" key={text}>
                <Link
                  disabled={!!disabled}
                  url={url}
                  text={text}
                  openInNewTab={openInNewTab}
                  className="text-indigo-300 hover:underline font-medium"
                />
                <Icon image="external-link" color="purple" />
              </div>
            );
          },
        )}
      </div>
    ),
  },
  {
    title: 'Step 4 (For all users) - Start reviewing PRs and more',
    subtitle: (
      <>
        In any Pull Request conversation, use commands such as
        <Code>{'/review'}</Code>
        to trigger PR review on demand,
        <Code>{'/describe'}</Code>
        to get detailed analysis and code suggestions, or
        <Code>{'/ask {question}'}</Code>
        in the context of your PR.
      </>
    ),
    children: (
      <>
        <div className="flex gap-6">
          <div className="flex gap-1 items-center">
            <Link
              url="https://qodo-merge-docs.qodo.ai/"
              text="Documentation"
              className="text-indigo-300 hover:underline font-medium"
            />
            <Icon image="external-link" color="purple" />
          </div>
          <div className="flex gap-1 items-center">
            <Link
              url="https://chromewebstore.google.com/detail/pr-agent-chrome-extension/ephlnjeghhogofkifjloamocljapahnl?hl=en"
              text="Chrome extension"
              className="text-indigo-300 hover:underline font-medium"
            />
            <Icon image="external-link" color="purple" />
          </div>
        </div>
        <img src={GitScreenshot} alt="pr review" />
      </>
    ),
  },
];

export const PRAgent = () => {
  const { isFreeUser, isOrganizationOwner } = useUser();

  return (
    <div className="flex flex-col gap-4">
      <PortalNav />
      <Animate>
        <div className="p-8 px-5 md:col-span-2 space-y-4">
          <div className="white text-2xl font-semibold">Qodo Merge</div>
          {isFreeUser && <UpgradeSection />}
          <ConnectSection />
          {getGitProvidersStepData(isOrganizationOwner).map((stepData) => (
            <QodoMergeStep {...stepData} />
          ))}
        </div>
      </Animate>
    </div>
  );
};
