import { useTeam } from 'components/hooks/useTeam';
import { Badge, InlineEditableInput, ViewContext } from 'components/lib';
import { useContext } from 'react';
import { useOrganization } from 'components/hooks/useOrganization';
import { isEmpty } from 'lodash';
import { SwitchTeamDropdown } from './switchTeamDropdown';
import { useUser } from '../../components/hooks/useUser';

export const EditableTeamTitle = ({ teamId }) => {
  const context = useContext(ViewContext);
  const { user, isOrganizationOwner } = useUser();
  const { team, updateTeamMutation } = useTeam(teamId);
  const { organization } = useOrganization();

  return (
    <div className="flex items-center gap-3">
      <InlineEditableInput
        initialValue={team?.name}
        isLoading={updateTeamMutation.isPending}
        onSave={(teamName) => {
          const data = { team_name: teamName };

          updateTeamMutation.mutate(data, {
            onSuccess: () => {
              context.notification.show(
                'Team name changed successfully',
                'success',
                true,
                'toast',
                'alert-circle',
              );
            },
          });
        }}
      />

      {isOrganizationOwner && <SwitchTeamDropdown />}

      {!isEmpty(organization?.sso_organization_id) ? (
        <Badge text="SSO ENABLED" className="bg-green-900 text-green-400" />
      ) : (
        <Badge text="SSO DISABLED" className="bg-gray-500 border-gray-500" />
      )}
    </div>
  );
};
