import { Button, Dropdown, Icon, Tooltip } from 'components/lib';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useTeam } from 'components/hooks/useTeam';
import { TIER_LEVELS } from '../../constants';
import { useUser } from 'components/hooks/useUser';
import { isEmpty } from 'lodash';
import { useOrganization } from '../../components/hooks/useOrganization';
import { useEffect, useState } from 'react';
import { useNavigate } from '../../components/lib';

export const ManageTeamDropdown = ({ teamId, refetchTeamUsers }) => {
  const navigate = useNavigate();
  const { user, isOrganizationOwner, refetchUser } = useUser();
  const {
    team,
    closeTeam,
    handleCreateStripeSession,
    isLoading,
    updateTeamMutation,
    refetchTeam,
  } = useTeam(teamId);
  const { organization } = useOrganization();
  const isEnterprise = user?.tier_level === TIER_LEVELS.ENTERPRISE;
  const isTeamOwner = user?.permission === 'owner';

  const showEnvSelection =
    (isOrganizationOwner || isTeamOwner) &&
    !isEmpty(organization?.baseUrlOptions);

  const Environment = () => {
    const [currentOption, setCurrentOption] = useState();
    const options = organization?.baseUrlOptions;
    const currentBaseUrl = team?.baseUrl ?? organization?.baseUrl;

    useEffect(() => {
      if (!options || !currentBaseUrl) return;
      const currentOption = options.find(
        (option) => option.baseUrl === currentBaseUrl,
      );
      setCurrentOption(currentOption);
    }, [options, currentBaseUrl]);

    return (
      <div className="border-b py-1 min-w-40 border-gray-400 pb-2 mx-3">
        <div className="ml-1 text-sm mb-2">Environment:</div>
        {options?.map((option) => (
          <div
            className="flex gap-2 items-center rounded hover:bg-gray-600 ml-1 cursor-pointer"
            key={option.name}
            onClick={() => {
              updateTeamMutation.mutate(
                { baseUrl: option.baseUrl },
                {
                  onSuccess: () => {
                    refetchTeamUsers();
                    refetchTeam();
                  },
                },
              );
            }}
          >
            <div className="w-[12px]">
              {currentOption?.name === option.name && (
                <Icon image="check" size={12} />
              )}
            </div>
            <div className="mr-4">
              <div className="text-xs">{option.name}</div>
              <div className="text-xs text-gray-500">{option.baseUrl}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Dropdown>
        <>
          <Dropdown.Trigger>
            <div>
              <Button
                loading={isLoading}
                small
                isDisabled={isLoading}
                text={
                  <>
                    {'Manage Team'}
                    <ChevronDownIcon />
                  </>
                }
                action={() => handleCreateStripeSession({ isManage: true })}
                className="flex items-center gap-2"
              />
            </div>
          </Dropdown.Trigger>
          <Dropdown.Menu>
            <>
              {!isEnterprise && (
                <Dropdown.MenuItem
                  onSelect={() => handleCreateStripeSession({ isManage: true })}
                >
                  Manage Plan
                </Dropdown.MenuItem>
              )}
              {(!isEnterprise || !isOrganizationOwner) && (
                <Dropdown.MenuItem disabled>
                  <div className="flex gap-2 items-center">
                    <span className="text-gray-500">Manage SSO</span>
                    <Tooltip
                      side="bottom"
                      content={
                        <p className="leading-6 text-xs">
                          Only org-owners in enterprise plan can configure SSO.{' '}
                          {!isEnterprise && (
                            <span>
                              <br />
                              Please contact us if you need SSO support.
                            </span>
                          )}
                        </p>
                      }
                    >
                      <div>
                        <Icon asChild image="info" color="#AEA1F1" size={14} />
                      </div>
                    </Tooltip>
                  </div>
                </Dropdown.MenuItem>
              )}
              {showEnvSelection && <Environment />}
              {team && (
                <Dropdown.MenuItem
                  onSelect={() =>
                    closeTeam({
                      callback: async () => {
                        await refetchUser();
                        navigate('/team');
                      },
                    })
                  }
                >
                  Close team
                </Dropdown.MenuItem>
              )}
            </>
          </Dropdown.Menu>
        </>
      </Dropdown>
    </div>
  );
};
