import { Tooltip } from 'components/lib';

export const IconLink = ({
  name,
  url = '',
  onClickHandler,
  imgUrl,
  tooltipContent,
  disabled = false,
  tooltipDelayDuration = 50,
  iconWidth = '22px',
}) => {
  const content = (
    <div
      className={`flex items-center space-x-2 md:space-x-4 p-3 md:p-4 rounded-md border border-zinc-600 transition-transform
        ${
          disabled
            ? 'opacity-50 cursor-not-allowed bg-zinc-200 dark:bg-zinc-800'
            : 'dark:bg-zinc-700 hover:cursor-pointer hover:scale-110'
        }`}
      onClick={() => onClickHandler(url)}
    >
      <img
        width={iconWidth}
        className={disabled ? 'grayscale' : ''}
        src={imgUrl}
        alt={name}
      />
    </div>
  );

  return tooltipContent ? (
    <Tooltip content={tooltipContent} delayDuration={tooltipDelayDuration}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
