import ChartIcon from 'assets/chart.svg';
import People from 'assets/people.svg';
import PersonalCardIcon from 'assets/personal-card.svg';
import UserCircleAddIcon from 'assets/user-circle-add.svg';
import {
  AuthContext,
  ViewContext,
  useInvite,
  useNavigate,
} from 'components/lib';
import { useTeam } from 'components/hooks/useTeam';
import { useUser } from 'components/hooks/useUser';
import { useContext } from 'react';
import { openUrl } from 'utils';
import { events, mixpanelService } from '../../app/analytics';
import { TIER_LEVELS } from '../../constants';
import { HomeActionCard } from './homeActionCard';

const discordURL = 'https://discord.com/invite/SgSxuQ65GF';

export const HomeActions = () => {
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const { user } = useUser();
  const { team, handleCreateStripeSession, isLoading } = useTeam(
    authContext?.user?.team_id,
  );
  const navigate = useNavigate();
  const { inviteMembersToTeam } = useInvite({ teamId: team?.id });

  const isEnterprise = user?.tier_level === TIER_LEVELS.ENTERPRISE;
  const userTeamId = user?.teams?.[0]?.team_id;
  const isTeamOwner =
    user?.permission === 'owner' && team && userTeamId === team?.id;

  const homeActions = [
    {
      title: 'Join Qodo Discord community',
      subTitle: 'Join Discord',
      icon: People,
      action: () => {
        mixpanelService.trackEvent(events.JOIN_DISCORD_CLICK);
        openUrl(discordURL);
      },
      isHidden: isEnterprise,
      className: 'bg-gradient-to-br from-[#382b4cb1] from-10%',
      iconClassName: 'border-[#6E598A]',
    },
    {
      title: 'Invite members to your team',
      subTitle: 'Invite members',
      icon: UserCircleAddIcon,
      action: () => {
        const teamId = authContext?.user?.team_id;
        if (!teamId) {
          viewContext.notification.show(
            'Please create a team first',
            'error',
            true,
            'toast',
            'alert-triangle',
          );
          navigate(`/team`);
          return;
        }
        inviteMembersToTeam((form, res) => {
          navigate(`/team/${teamId}/users`);
          viewContext.notification.show(
            res.message,
            'success',
            true,
            'toast',
            'heart',
          );
        });
      },
    },
    {
      isHidden: isEnterprise || !isTeamOwner,
      title: 'Manage and upgrade your plan',
      isDisabled: isLoading,
      icon: PersonalCardIcon,
      subTitle: 'Manage plans',
      action: () => handleCreateStripeSession({ isManage: true }),
    },
    {
      isHidden: authContext.user.team_id,
      title: 'Upgrade you plan',
      icon: ChartIcon,
      subTitle: 'Compare plans',
      action: () => navigate('/plans'),
    },
  ];

  return (
    <div className="flex flex-col items-start space-y-4 md:space-y-6 rounded-md self-stretch w-full">
      <div className="flex flex-wrap gap-3 md:gap-4 w-full">
        {homeActions.map((action) => {
          const { isHidden, title } = action;
          return !isHidden && <HomeActionCard key={title} action={action} />;
        })}
      </div>
    </div>
  );
};
