import Bitbucket from 'assets/Bitbucket.svg';
import Github from 'assets/Github.svg';
import Gitlab from 'assets/Gitlab.svg';
import JiraCloud from 'assets/JiraCloud.svg';
import JiraServer from 'assets/JiraServer.svg';
import { useNavigate } from 'react-router-dom';
import { openUrl } from 'utils';
import { events, mixpanelService } from '../../app/analytics';
import { jiraCloudQodoConnect, jiraServerQodoConnect } from '../../constants';
import { IconLink } from 'components/qodo/qodoIconLink';
import { useUser } from 'components/hooks/useUser';

const eventNames = {
  JiraCloud: events.JIRA_CLOUD_CONNECT_CLICK,
  JiraServer: events.JIRA_SERVER_CONNECT_CLICK,
};

export const QodoMergeButtons = () => {
  const navigate = useNavigate();

  const onQodoMergeClick = () => navigate('/qodo-merge');

  const providers = [
    {
      name: 'Github',
      imgUrl: Github,
      onClickHandler: onQodoMergeClick,
      tooltipContent: 'Github',
    },
    {
      name: 'Gitlab',
      imgUrl: Gitlab,
      onClickHandler: onQodoMergeClick,
      tooltipContent: 'Gitlab',
    },
    {
      name: 'Bitbucket',
      imgUrl: Bitbucket,
      onClickHandler: onQodoMergeClick,
      tooltipContent: 'Bitbucket',
    },
  ];

  return (
    <div className="flex gap-2 flex-wrap">
      {providers.map((item) => (
        <IconLink key={item.name} {...item} />
      ))}
    </div>
  );
};

export const TicketTrackingSystemsButtons = () => {
  const { isOrganizationOwner } = useUser();

  const onTicketTrackingSystemClick = (url, name) => {
    mixpanelService.trackEvent(
      eventNames[name] ?? 'Ticket tracking system click',
      {
        ticketTrackingSystemType: name,
      },
    );
    openUrl(url, true);
  };

  const links = [
    {
      name: 'JiraCloud',
      imgUrl: JiraCloud,
      url: jiraCloudQodoConnect,
      tooltipContent: isOrganizationOwner
        ? 'Jira Cloud'
        : 'Only organization owner can connect to Jira Cloud',
      disabled: !isOrganizationOwner,
      onClickHandler: (url) => onTicketTrackingSystemClick(url, 'JiraCloud'),
    },
    {
      name: 'JiraServer',
      imgUrl: JiraServer,
      url: jiraServerQodoConnect,
      tooltipContent: isOrganizationOwner
        ? 'Jira Server'
        : 'Only organization owner can connect to Jira Server',
      disabled: !isOrganizationOwner,
      onClickHandler: (url) => onTicketTrackingSystemClick(url, 'JiraServer'),
    },
  ];

  return (
    <div className="flex flex-row gap-2">
      {links.map((item) => (
        <IconLink key={item.name} {...item} />
      ))}
    </div>
  );
};
