import JetBrainLogo from 'assets/JetBrains.svg';
import PyCharm from 'assets/PyCharm.svg';
import VSCode from 'assets/VSCode.svg';
import WebStorm from 'assets/Webstorm.svg';
import Intellij from 'assets/intellij.svg';
import { openUrl } from 'utils';
import { events, mixpanelService } from '../../app/analytics';
import { IconLink } from 'components/qodo/qodoIconLink';

export const QodoGenButtons = () => {
  const onQodoGenClick = (url, name) => {
    const eventName =
      name === 'VSCode'
        ? events.VSCODE_MARKETPLACE_LINK_CLICK
        : events.JB_MARKETPLACE_LINK_CLICK;
    mixpanelService.trackEvent(eventName, {
      ideType: name,
    });
    openUrl(url, true);
  };

  const links = [
    {
      name: 'VSCode',
      imgUrl: VSCode,
      tooltipContent: 'VSCode',
      onClickHandler: (url) => onQodoGenClick(url, 'VSCode'),
      url: 'https://marketplace.visualstudio.com/items?itemName=Codium.codium',
    },
    {
      name: 'JetBrains',
      imgUrl: JetBrainLogo,
      tooltipContent: 'JetBrains',
      onClickHandler: (url) => onQodoGenClick(url, 'JetBrains'),
      url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
    },
    {
      name: 'PyCharm',
      imgUrl: PyCharm,
      tooltipContent: 'PyCharm',
      onClickHandler: (url) => onQodoGenClick(url, 'PyCharm'),
      url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
    },
    {
      name: 'InteliJ',
      imgUrl: Intellij,
      tooltipContent: 'IntelliJ',
      onClickHandler: (url) => onQodoGenClick(url, 'InteliJ'),
      url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
    },
    {
      name: 'WebStorm',
      imgUrl: WebStorm,
      tooltipContent: 'WebStorm',
      onClickHandler: (url) => onQodoGenClick(url, 'WebStorm'),
      url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
    },
  ];

  return (
    <div className="flex gap-2 flex-wrap">
      {links.map((item) => (
        <IconLink key={item.name} {...item} />
      ))}
    </div>
  );
};
