/***
 *
 *   LINK
 *   Routes a new view within the application router
 *   Use this instead of <a> to avoid reloading the page
 *
 *   PROPS
 *   children: children to render (component(s), required)
 *   color: dark or light (string, optional, default: blue)
 *   text: link text (string, required)
 *   title: link title (string, required)
 *   url: the destination (string, required)
 *
 **********/

import { NavLink } from 'react-router-dom';
import { ClassHelper } from 'components/lib';
import Style from './link.tailwind.js';

export function Link(props) {
  const linkStyle = ClassHelper(Style, {
    white: props.color === 'white',
    dark: props.color === 'dark',
    defaultColor: !props.color,
    disabled: !!props.disabled,
    className: props.className,
  });

  if (props?.url?.includes('http') || props?.url?.includes('https')) {
    return (
      <a
        href={props.url}
        title={props.title}
        className={linkStyle}
        target={props.openInNewTab ? '_blank' : '_self'}
        rel={props.openInNewTab ? 'noopener noreferrer' : undefined}
      >
        {props.text}
      </a>
    );
  }

  return (
    <NavLink
      to={props.url}
      className={linkStyle}
      title={props.title}
      activeclassname="active"
    >
      {props.children || props.text}
    </NavLink>
  );
}
